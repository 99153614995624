function CloseButton(props) {
  return (
    <span
      className="material-symbols-rounded closebutton"
      onClick={props.onClick}
    >
      close
    </span>
  );
}
export default CloseButton;
