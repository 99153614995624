function Failure() {
  return (
    <div className="d-block h-100 w-auto rounded-3 rocky_bg rounded-3">
      <div className="margin container-fluid">
        <h1 className="text-danger">What went wrong?</h1>

        <p className="reactText">
          I'm afraid something went wrong with your submission.
          <br />
          Please try again later
        </p>
      </div>
    </div>
  );
}

export default Failure;
