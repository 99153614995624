function Success() {
  return (
    <div className="d-block h-100 w-auto mountains_bg rounded-3">
      <div className="inner_container container-fluid">
        <h1 className="modalh1">Your subscription was successful</h1>
      </div>
    </div>
  );
}

export default Success;
