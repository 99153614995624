import { useForm } from 'react-hook-form';
import axios from 'axios';



function Intro(props) {
  let link = 'https://www.trailfinders.com/privacy';
  if (window.siteVar.referer === 'IE') {
    link = 'https://www.trailfinders.ie/privacy';
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldUnregister: false,
    defaultValues: {
      title: '',
      GivenName: '',
      FamilyName: '',
      Email: '',
      EmailConfirm: '',
    },
  });
  return (
    <div className='d-flex align-items-center container-fluid justify-content-center beach_bg rounded-3 h-100'>
    <div className="my-auto w-100 h-100 d-flex flex-column justify-content-center">
      <div>
      <img src="https://cdn.trailfinders.com/tf-subscribe.png?width=125" 
            alt="subscribe"
            className='mt-3'>

       </img>
      </div>
     <h1 className="modalh1">TF's Bulletin</h1>
        <h2 className="modalh2">Sign up for TF's Special Offers & Travel Articles</h2>

        <form
        className="ModalForm w-100"
        onSubmit={handleSubmit((data) => {
          
          const postData = {};
          postData.mongoData = {};
          postData.mongoData.email = data.Email;
          postData.mongoData.title = '';
          postData.mongoData.name = '';
          postData.mongoData.surname = '';
          postData.mongoData.source = 'web';
          postData.mongoData.partition = window.siteVar.referer;

          postData.send_to = data.Email;
          postData.version = '1';
          postData.ieBooking = false;
          postData.email = data.Email;
          postData.title = '';
          postData.firstname = '';
          postData.surname = '';
          postData.partition = window.siteVar.referer;
          postData.emailUI = true;
          postData.emailId = '1';
          if (window.siteVar.referer === 'IE') {
            postData.ieBooking = true;
          }

          axios
            .post(process.env.REACT_APP_MARKETING_API, postData)
            .then((response) => {
              let res = response.status;
              // res = 404; if want to test failed page state, uncomment this out.
              
              if (res === 200) {
                const onSuccess = true;
                console.log('res = 200')
                props.onResponse(onSuccess);
              } else {
                const onSuccess = false;
                console.log('res failure')
                props.onResponse(onSuccess);
              }
              
            });
        })}
      >
             <div className="col col-10 col-lg-6 col-xl-5 mx-auto">
          <label className="formtext">Email address:</label>
          <br />
          <input
            placeholder="example@email.com"
            name="Email"
            id="emailInput"
            {...register('Email', {
              required: 'Please enter your email address',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Please check your email address',
              },
            })}
            className="textarea w-100 text-center rounded-0 border-0"
          ></input>
          <p className="errorMsg">{errors.Email?.message}</p>
        </div>
        <div className="flexcentre">
          <button className="btn btn-md btn-warning border-0 rounded-0 ps-5 pe-5 pt-2 pb-2" type="submit" style={{backgroundColor:"rgb(255,164,9)"}}>
            Subscribe
          </button>
        </div>
        </form>
      <a className="modalLink" href={link}>
         <small> Click here to read our full privacy policy.</small>  
          </a>
         
    </div>
    </div>
  );
}

export default Intro;
