import Modal from './components/Modal';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';


function App() {
  const [isOpen, setIsOpen] = useState(false);
  const noCookies = Cookies.get('accepts_cookies') || false

  useEffect(() => {
    // make sure doesnt open if on an email referred page
    const hasAlreadySubscribed = Cookies.get('EmailCookie');
    const hasSubscribeCookie = Cookies.get('subscribe_email');
    const hasDeclined = window.sessionStorage.getItem('noCookies');
    if (!hasSubscribeCookie) {
      if(!hasAlreadySubscribed && !hasDeclined){
      setTimeout(() => {
        setIsOpen(true);
      }, 10000);
    }
  }}, []);

  function closeModalHandler() {
    setIsOpen(false);
    // set cookies when modal is closed
    const day = new Date();
    const millisec = day.getTime();
    // const acceptsValue = Cookies.get('accepts_cookies')
      window.sessionStorage.setItem('noCookies', 'noCookies');
      noCookies && noCookies.includes('performance_') && Cookies.set('subscribe_email', millisec, { expires: 90 }); //only set if users cookie preferences allow
    
  };

  return <div className="ModalSize">
  {isOpen && <Modal onClick={closeModalHandler} onClose={closeModalHandler} />}</div>
  ;
}

export default App;
