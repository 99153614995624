import { useState } from 'react';
import CloseButton from './Closebutton';
import Success from './Success';
// import Brochures from './zz_Brochures';
import Overlay from './Overlay';
import Intro from './Intro';
// import AddressCapture from './zz_Addresscapture';
import Failure from './Failure';
import ReactGA from "react-ga4";
const gaId = window.siteVar.referer === 'UK' ? 'G-SKPFMZ4RJB' : 'G-CT46K5CJXJ'
ReactGA.initialize(gaId);
function Modal(props) {
  const [Page, setPage] = useState('intro');
  // useEffect(() => {
  //   const CoinFlip = Math.random();
  //   if (CoinFlip > 0.5) {
  //     setBrochureorder(true);
  //     setIntro(false);
  //   }
  // }, []);

  function handleResponse(onSuccess) {
    if (onSuccess === true) {
      setPage('success');
      const queryString = new URLSearchParams(window.location.search)
      console.log('queryString',queryString);
      const urlParams = new URLSearchParams(queryString);
      const cid = urlParams.get("cid")
      if (urlParams.has('cid')){
        console.log('Sending cid specific results', cid);
        ReactGA.event({
          category: "emailSignUp",
          action: "submit success",
          label: "exitModal."+cid+"",
        });
      } else {
       console.log('sending');
        ReactGA.event({
          category: "emailSignUp",
          action: "submit success",
          label: "exitModal"
        });
      }
    } else {
      console.log('failure not sending');
      setPage('failure');
    }
  }
  return (
    <div>
      <div className="reactModal shadow-lg col col-10 col-lg-6 h-100 mx-auto my-auto position-fixed align-items-end rounded-3">
        <CloseButton onClick={props.onClick} />
        {Page === 'intro' && <Intro onResponse={handleResponse}/>}
        {Page === 'success' && <Success className="h-100"/>}
        {Page === 'failure' && <Failure className="h-100"/>}
        
      </div>
      <Overlay onClose={props.onClose} />
    </div>
  );
}
export default Modal;
