import Modal from 'react-bootstrap/Modal';
import { useEffect, useState} from 'react';
import Cookies from 'js-cookie';
import { ModalBody } from 'react-bootstrap';
import Intro from './Intro';


const SubscribeModal = () => {
    const AcceptCookies = Cookies.get('accepts_cookies') || false
    const [isOpen, setIsOpen] = useState(false);

    const closeModalHandler = () => {
        setIsOpen(false);
        // set cookies when modal is closed
    const day = new Date();
    const millisec = day.getTime();
  
      window.sessionStorage.setItem('noCookies', true);
      AcceptCookies && AcceptCookies.includes('performance_') && Cookies.set('subscribe_email', millisec, { expires: 90 }); 
      //only set if users cookie preferences allow
    }

    useEffect(() => {
        // make sure doesnt open if on an email referred page
    const hasAlreadySubscribed = Cookies.get('EmailCookie');
    const hasSubscribeCookie = Cookies.get('subscribe_email');
    const hasDeclined = window.sessionStorage.getItem('noCookies');
    const hasSubscribed = window.sessionStorage.getItem('hasSubscribed');
  

    if (!hasSubscribeCookie) {
      if(!hasAlreadySubscribed && !hasDeclined && !hasSubscribed) {
      setTimeout(() => {
        setIsOpen(true);
      }, 10000);
    }}
    }, []);
    return (
   <>
    <Modal show={isOpen} onHide={closeModalHandler} className='col col-12 col-md-9 col-lg-4 col-xl-3 rounded-5' centered>
    <ModalBody className='p-0 position-relative rounded-5'>
      <i className='icon-close text-warning position-absolute top-0 end-0 p-3 cursor-pointer' onClick={()=>closeModalHandler()}></i>
       <Intro />
    </ModalBody>
    </Modal>
   </>
    );
}
export default SubscribeModal;